import React, { useState } from 'react'
import SlideOutContact from './SlideOutContact'

const SalesPitch = () => {
  const [isVisible, setIsVisible] = useState(false);

    return (
      <div className="ng-sales-pitch-component">
        <img
          className="ng-full-image"
          alt="Corpus Christi texas Lubbock Website SEO dallas"
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242656/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/stop_buying_software.svg"
        />
        <p className="ng-main-p">
          Seriously, stop it. Would you expect to buy a new race car and assume
          it will beat the competition every year for the next 10 years? No!
          That’s ridiculous. Imagine trying to win a race and never improving
          your race car… I bet{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/Michael_Schumacher"
          >
            Michael Schumacher
          </a>{' '}
          will back us up and say you will NEVER win with that kind of attitude
          no matter how good your driver is. You have to upgrade, maintain, and
          tweak your technology. In today’s fast paced world, technology becomes
          outdated in just a few years. New tech comes out every day that might
          benefit your business tremendously. And having our team on retainer is
          almost like leasing a car; You get a new model every year. And yes,
          software depreciates just the same! We stay on the cutting edge so
          that you do too. Spare yourself the time of hunting for another
          contract and negotiating more terms. Whether you ask for it or we
          recommend it, it’s as easy as a phone call, text, chat or email to add
          technology into your business for improvements. Don’t think technology
          can help your business? HA! No offense, but you couldn’t be more wrong
          - Technology is a critical investment for every business.
        </p>
        <img
          className="ng-full-image"
          alt="Lubbock developer website corpus christi dallas"
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/hire-full-team.jpg"
        />
        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            I agree
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>

        {/* Benefits Section Start */}
        <h1>Benefits</h1>
        <div className="benefits-container">
          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242659/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_1.svg"
            />
            <h4>Cost Efficiency</h4>
            <p>
              Get the best value. Get the best developer. No payroll taxes.
              Don’t pay for learning time. And don’t pay for unnecessary work.
            </p>
          </div>
          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242658/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_3.svg"
            />
            <h4>Experience</h4>
            <p>
              The experience we bring to the table speaks for itself. You
              couldn’t dream of a better dream team.
            </p>
          </div>
          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242659/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_2.svg"
            />
            <h4>A team of minds is far superior to just one</h4>
            <p>
              Our teams have a much broader knowledge base than any one person.
              Together we can tackle any problem.
            </p>
          </div>

          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242660/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_4.svg"
            />
            <h4>Ongoing Support</h4>
            <p>
              Software is an evolving product. Technology is a product itself
              that needs to be constantly improved, tweaked and upgraded.
            </p>
          </div>
          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_9.svg"
            />
            <h4>Quick Response</h4>
            <p>
              We are just a chat, text, phone call or email away for whenever
              something comes up.
            </p>
          </div>

          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242662/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_6.svg"
            />
            <h4>Get the right person for the job</h4>
            <p>
              We will devote the right type of developer to your project. This
              means you get the most experienced developer for whatever task you
              need completed from us.
            </p>
          </div>

          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242662/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_7.svg"
            />
            <h4>Peace of mind</h4>
            <p>
              Don’t worry about the advancing integration of technology into
              business - that’s our specialty. We can help you understand how to
              improve your bottom line with technology.
            </p>
          </div>
          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_8.svg"
            />
            <h4>Focus on what matters</h4>
            <p>
              You don’t need to learn technology. That’s why we are here! You
              can focus on your business. We will focus on making it better with
              technology.
            </p>
          </div>

          <div className="benefits-item">
            <img
              alt="mobile app development"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242660/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/benefits_icon_5.svg"
            />
            <h4>Ask and you shall receive</h4>
            <p>
              We don’t need to negotiate every little detailed bullet point of
              the deliverables. If you want something done just tell us and we
              will get it done.
            </p>
          </div>
        </div>
        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            I'm interested
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>
        {/* Benefits Section End */}

        {/* If you want Section Start */}
        <h1>If you want</h1>
        <div className="checkmark-container">
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>No more "What's your budget?" Questions</h3>
          </div>
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>Unlimited revisions</h3>
          </div>
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>No more per-project negotiations</h3>
          </div>
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>Immediate technical assistance with anything you need</h3>
          </div>
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>Constantly improved business</h3>
          </div>
          <div className="checkmark-item">
            <p>& most importantly</p>
          </div>
          <div className="checkmark-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242655/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/checkmark.svg" />
            <h3>To stay ahead of your competition</h3>
          </div>
        </div>

        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            I want all of those things
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>
        {/* If you want Section End */}

        {/* What can we do? Section Start */}
        <h1>What can we do?</h1>
        <div className="what-we-can-do-container">
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_1.png" />
            <h4>Mobile Apps</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_2.png" />
            <h4>Shopify</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_3.png" />
            <h4>Web Apps</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_4.png" />
            <h4>Websites</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242666/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_5.png" />
            <h4>WordPress</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242662/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_6.png" />
            <h4>SEO</h4>
          </div>

          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242662/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_7.png" />
            <h4>Industrial Solutions</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242661/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_9.png" />
            <h4>Machine Learning</h4>
          </div>
          <div className="what-we-can-do-item">
            <img src="https://res.cloudinary.com/tylerholden/image/upload/v1557242660/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/hire-us/what_we_can_do_8.png" />
            <h4>Enterprise Systems</h4>
          </div>
        </div>
        <h4 className="and-much-more">And much more...</h4>
        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            I want one of those
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>
        {/* What can we do? Section End */}

        {/* Pricing Section Start */}
        <h1 id="services">Okay, what does it cost?</h1>
        <div className="price-table">
          <div className="price-table-item price-item-1">
            <h3>Support Team</h3>
            <div className="divider" />
            <p>
              <i>Equivalent to one part time developer/IT guru.</i>
            </p>
            <h4>Great for</h4>
            <ul>
              <li>
                Having a tech team at your disposal for any day-to-day issues
                that might occur
              </li>
              <li>
                Maintenence on a small live project for monthly changes,
                updates, tweaks and bug fixes
              </li>
              <li>Snail-paced project build</li>
            </ul>
            <h3 className="price">Ask For Details</h3>
            <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn-outline">
              Is this for me?
            </a>
          </div>
          <div className="price-table-item price-item-2">
            <h3>Consulting Team</h3>
            <div className="divider" />
            <p>
              <i>Equivalent to three part time developers/IT gurus.</i>
            </p>
            <h4>Great for</h4>
            <ul>
              <li>Simple Website build/upkeep</li>
              <li>Small IT solutions</li>
              <li>Simple Shopify Store setup/upkeep</li>
              <li>Slow-paced MVP mobile or web application</li>
            </ul>
            <h3 className="price">Ask For Details</h3>
            <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn-outline">
              Is this for me?
            </a>
          </div>
          <div className="price-table-item price-item-3">
            <h3>Build Team</h3>
            <div className="divider" />
            <p>
              <i>Equivalent to one full time developer/IT guru.</i>
            </p>
            <h4>Great for</h4>
            <ul>
              <li>Custom Website build/upkeep</li>
              <li>Medium IT solutions</li>
              <li>Custom Shopify Theme & maintenence</li>
              <li>Mobile Application build/upkeep</li>
              <li>Web application build/upkeep</li>
            </ul>
            <h3 className="price">Ask For Details</h3>
            <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn-outline">
              Is this for me?
            </a>
          </div>
          <div className="price-table-item price-item-4">
            <h3>Velocity Team</h3>
            <div className="divider" />
            <p>
              <i>Equivalent to three full time developers/IT gurus.</i>
            </p>
            <h4>Great for</h4>
            <ul>
              <li>Rapid Custom Website build/upkeep</li>
              <li>Large IT solutions</li>
              <li>Rapid Custom Shopify Theme build/upkeep</li>
              <li>Rapid Mobile Application build/upkeep</li>
              <li>Rapid Web application build/upkeep</li>
            </ul>
            <h3 className="price">Ask For Details</h3>
            <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn-outline">
              Is this for me?
            </a>
          </div>
        </div>
        <p className="terms">
          <i>
            These service packages do not include complex solutions such as
            Machine Learning, Neural Networks, and Advanced Data Analytics. As
            you can imagine, projects that require “the big guns” have a higher
            cost. We have skilled and experienced team members dedicated
            strictly for these types of advanced projects. These service
            packages do not include any hosting costs, server costs, deployment
            costs or any additional costs associated with your project. These
            rates are simply for the use of one of our development teams and
            additional costs will be agreed upon prior to commitment.
          </i>
        </p>
        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            I want to inquire about your services
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>
        {/* Pricing Section End */}

        {/* Don't agree Section Start */}
        <h1>Still don't agree?</h1>
        <div className="call-to-action-button">
          <a href="#footerContactForm" onClick={() => setIsVisible(true)} className="btn btn--dark">
            Tell us why you don't like it
          </a>
          <p>
            <i>This button will take you to a short form to fill out</i>
          </p>
        </div>
        <SlideOutContact isVisible={isVisible} setIsVisible={setIsVisible}/>
        {/* Don't agree Section End */}
      </div>
    )
  }

export default SalesPitch
