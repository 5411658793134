import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import SalesPitch from '../components/SalesPitch'

import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'

class HireUs extends React.Component {
  static propTypes = {
    isStatic: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node,
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="Hire Us | NEXTGEN Code Company"
          meta={[
            {
              name: 'description',
              content:
                "Website's, SEO, search engine optimization, mobile app's, web applications, UI UX design, and more serving the Dallas, Lubbock and Corpus Christi Texas areas.",
            },
          ]}
        />
        <SubHero
          defaultBackText={'Services'}
          defaultBackURL={'/services'}
          title="Call us 'My Tech Guys'"
          subtitle=""
        />

        <section className="ng-hire-us-section">
          <SalesPitch />
        </section>
      </Layout>
    )
  }
}

export default HireUs
