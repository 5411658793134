import React, { useRef, useEffect } from 'react'
import { bool, node } from 'prop-types'
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components'
import useForm from '../hooks/useForm'

const Inner = styled.div`
  &:before,
  &:after {
    content: '';
    display: table;
  }
`

const visibleStyle = { height: 'auto', opacity: 1, overflow: 'visible' }
const hiddenStyle = { opacity: 0, height: 0, overflow: 'hidden' }

function getElementHeight(ref) {
  return ref.current ? ref.current.getBoundingClientRect().height : 0
}

/** The children of this component will slide down on mount and will slide up on unmount */
const SlideToggleContent = ({ isVisible, children, forceSlideIn }) => {
  const isVisibleOnMount = useRef(isVisible && !forceSlideIn)
  const containerRef = useRef(null)
  const innerRef = useRef(null)

  const transitions = useTransition(isVisible, null, {
    enter: () => async (next, cancel) => {
      const height = getElementHeight(innerRef)

      cancel()

      await next({ height, opacity: 1, overflow: 'hidden' })
      await next(visibleStyle)
    },
    leave: () => async (next, cancel) => {
      const height = getElementHeight(containerRef)

      cancel()

      await next({ height, overflow: 'hidden' })
      await next(hiddenStyle)

      isVisibleOnMount.current = false
    },
    from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
    unique: true,
  })

  return transitions.map(({ item: show, props: springProps, key }) => {
    if (show) {
      return (
        <animated.div ref={containerRef} key={key} style={springProps}>
          <Inner ref={innerRef}>{children}</Inner>
        </animated.div>
      )
    }

    return null
  })
}

SlideToggleContent.defaultProps = {
  forceSlideIn: false,
}

SlideToggleContent.propTypes = {
  /** Should the component mount it's childeren and slide down */
  isVisible: bool.isRequired,
  /** Makes sure the component always slides in on mount. Otherwise it will be immediately visible if isVisible is true on mount */
  forceSlideIn: bool,
  /** The slidable content elements */
  children: node.isRequired,
}

const SlideOutContact = ({isVisible, setIsVisible}) => {

  const { handleInputChange, inputs } = useForm({
    Name: '',
    Email: '',
  })

  useEffect(() => {
    if (inputs.Name.length > 0 || inputs.Email.length > 0) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [inputs.Name, inputs.Email])

  return (
    <div className="container ng-contactHome-container" id="footerContactForm">
      <form
        className="ng-contact-form"
        name="footer-contact"
        method="POST"
        data-netlify="true"
        netlify="true"
      >
        <h3>Contact Us</h3>
        <h4>Let's transform your business</h4>
        <div className="ng-contact-textInputContainer">
          <input type="hidden" name="form-name" value="footer-contact" />
          <div className="ng-contact-input">
            <label>Full Name*</label>
            <input
              type="text"
              name="Name"
              onChange={handleInputChange}
              value={inputs.Name}
              required
            />
          </div>
          <div className="ng-contact-input">
            <label>Email*</label>
            <input
              type="email"
              name="Email"
              onChange={handleInputChange}
              value={inputs.Email}
              required
            />
          </div>
        </div>

        <SlideToggleContent isVisible={isVisible}>
          <div className="ng-contact-textInputContainer">
            <div className="ng-contact-input">
              <label>Company Name*</label>
              <input type="text" name="Company Name" required />
            </div>
            <div className="ng-contact-input">
              <label>Phone Number*</label>
              <input type="tel" name="Phone Number" />
            </div>
          </div>

          <label className="ng-contact-servicesNeeded">Services Needed</label>

          <div className="ng-contact-checkBoxes">
            <div className="ng-contact-checkBox">
              <label htmlFor="Web Development">
                <input
                  type="checkbox"
                  placeholder="Web Development"
                  name="Web Development"
                />
                Web Development
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="SEO">
                <input
                  type="checkbox"
                  placeholder="SEO"
                  name="SEO"
                  value="SEO"
                />
                SEO
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Mobile App">
                <input
                  type="checkbox"
                  placeholder="Mobile App"
                  name="Mobile App"
                />
                Mobile App
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Shopify">
                <input type="checkbox" placeholder="Shopify" name="Shopify" />
                Shopify
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Industrial Solution">
                <input
                  type="checkbox"
                  placeholder="Industrial Solution"
                  name="Industrial Solution"
                />
                Industrial Solution
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Machine Learning">
                <input
                  type="checkbox"
                  placeholder="Machine Learning"
                  name="Machine Learning"
                />
                Machine Learning
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="UI/UX Design">
                <input
                  type="checkbox"
                  placeholder="UI/UX Design"
                  name="UI/UX Design"
                />
                UI/UX Design
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Enterprise Solution">
                <input
                  type="checkbox"
                  placeholder="Enterprise Solution"
                  name="Enterprise Solution"
                />
                Enterprise Solution
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="WordPress">
                <input
                  type="checkbox"
                  placeholder="WordPress"
                  name="WordPress"
                />
                WordPress
              </label>
            </div>
            <div className="ng-contact-checkBox">
              <label htmlFor="Code Consulting">
                <input
                  type="checkbox"
                  placeholder="Code Consulting"
                  name="Code Consulting"
                />
                Code Consulting
              </label>
            </div>
          </div>
        </SlideToggleContent>
        <div className="ng-contact-textArea">
          <label>Description of Services Needed*</label>
          <textarea name="Description of Services Needed" rows="1" required />
        </div>
        <button className="btn btn--dark" type="submit">
          Submit
        </button>
      </form>
    </div>
  )
}

export default SlideOutContact
